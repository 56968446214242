import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

interface PlayerCardProps {
    player: Player
}

interface Player {
    name: string
    number: number
    class?: string
    positions?: string[]
    sr_id: string
    image?: IGatsbyImageData
}

const PlayerCard: React.FC<PlayerCardProps> = ({ player }) => {
    return (
        <div className="card is-primary">
            <div className="card-image card-header">
                {player.image ? <GatsbyImage image={player.image} alt={player.name} /> : null}
            </div>
            <div className="card-content">
                <div className="content">
                    <p className="title has-text-centered">{player.name}</p>
                    <div className="level">
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">Jersey #</p>
                                <p className="title is-size-7">{player.number}</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">Class Of</p>
                                <p className="title is-size-7">{player.class ?? ""}</p>
                            </div>
                        </div>
                        <div className="level-item has-text-centered">
                            <div>
                                <p className="heading">Position(s)</p>
                                <p className="title is-size-7">{player.positions ? player.positions.join(" · ") : ""}</p>
                            </div>
                        </div>
                    </div>
                    <div className="level">
                        <div className="level-item has-text-centered">
                            <a
                                className="button is-small is-rounded is-link "
                                href={`https://sportsrecruits.com/${player.sr_id}`}
                                target="_window"
                            >
                                SportsRecruits Profile
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PlayerCard
