import { graphql, PageProps } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import PlayerCard from "../components/player-card"
import SEO from "../components/seo"

interface Player {
    id: string
    name: string
    number: number
    class?: string
    positions?: string[]
    sr_id: string
    image: string
}

export const playerImage = graphql`
    fragment playerImage on File {
        childImageSharp {
            gatsbyImageData(width: 400, height: 400, transformOptions: { fit: COVER, cropFocus: NORTH })
        }
    }
`

export const query = graphql`
    query {
        allPlayer {
            nodes {
                id
                name
                class
                number
                positions
                sr_id
                image
            }
        }
        generic: file(relativePath: { eq: "players/generic.jpg" }) {
            ...playerImage
        }
        abby_thompson: file(relativePath: { eq: "players/abby_thompson.png" }) {
            ...playerImage
        }
        annie_kester: file(relativePath: { eq: "players/annie_kester.png" }) {
            ...playerImage
        }
        camille_bonina: file(relativePath: { eq: "players/camille_bonina.png" }) {
            ...playerImage
        }
        grace_bonina: file(relativePath: { eq: "players/grace_bonina.png" }) {
            ...playerImage
        }
        jennifer_brzostek: file(relativePath: { eq: "players/jennifer_brzostek.png" }) {
            ...playerImage
        }
        maddie_burns: file(relativePath: { eq: "players/maddie_burns.png" }) {
            ...playerImage
        }
        maeve_driscoll: file(relativePath: { eq: "players/maeve_driscoll.png" }) {
            ...playerImage
        }
        redLogo: file(relativePath: { eq: "logo-red.jpg" }) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
    }
`
interface PlayerData {
    allPlayer: { nodes: Player[] }
}

interface ImageData {
    [key: string]: { childImageSharp: { gatsbyImageData: IGatsbyImageData } }
}

type Data = PlayerData & ImageData

const Players: React.FC<PageProps<Data>> = ({ data }) => {
    // const players = useMemo(() => chunk(data.allPlayer.nodes, 4), [data])
    return (
        <Layout>
            <SEO title="Players" description="Top talent players from all around Massachusetts and all high shcool levels." />
            <section className="hero is-dark mt-6">
                <div className="hero-body">
                    <div className="container">
                        <p className="title">CM Thunder Gold 18U Roster - Summer 2022</p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="columns is-multiline">
                        {data.allPlayer.nodes.map(player => {
                            const { image, ...rest } = player
                            return (
                                <div
                                    className="column is-full-mobile is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd"
                                    key={player.id}
                                >
                                    <PlayerCard player={{ image: data[image].childImageSharp.gatsbyImageData, ...rest }} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Players
